import { render, staticRenderFns } from "./StockRegisterPage3.vue?vue&type=template&id=6a01c5c8&scoped=true&"
import script from "./StockRegisterPage3.vue?vue&type=script&lang=js&"
export * from "./StockRegisterPage3.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a01c5c8",
  null
  
)

export default component.exports